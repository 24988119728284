import {
  FileTextOutlined,
  LeftOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { Button, Card, Flex, Row, Skeleton, Tabs, type TabsProps } from "antd";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { getNotesDetails, updateNote } from "../../service/notes.service";
import {
  setCustomiseSectionState,
  setLoadingNotesState,
  setNotesViewVisible,
} from "../../slices/appStateSlice";
import {
  resetNotesDetails,
  setActiveKey,
  setNotesDetails
} from "../../slices/notesSlice";
import EmailView from "./EmailView.component";
import styles from "./notes.module.scss";
import NotesHeader from "./NotesHeader.component";
import NotesNiew from "./NotesView.component";
import TranscriptView from "./TranscriptView.component";

export default function Notes() {
  const componentName = "notes";
  const dispatch = useAppDispatch();
  const { loadingNotes, isDemoPath, isMobilePath } = useAppSelector(
    (state) => state.appState,
  );
  const { notesDetails } = useAppSelector((state) => state.notes);
  const { userInfo } = useAppSelector((state) => state.auth);

  const markNoteAsRead = async (recordingId: string | number) => {
      if (isDemoPath) return;
      const updateNoteData = {
        audio_id: recordingId,
        is_read: true,
      };
      await updateNote(updateNoteData)
        .then((res) => {
          dispatch(setNotesDetails(res.data));
        })
        .catch((error) => {
          console.error("Error marking note as read:", error);
        });
  };

  React.useEffect(() => {
    if (notesDetails?.id) markNoteAsRead(notesDetails.id);
  }, [notesDetails?.id]);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Note",
      children: <NotesNiew />,
      icon: <FileTextOutlined />,
    },
    {
      key: "2",
      label: "Email",
      children: <EmailView />,
      icon: <MailOutlined />,
    },
  ];

  if (userInfo.is_transcript) {
    items.push({
      key: "3",
      label: "Transcript",
      children: <TranscriptView />,
      icon: <FileTextOutlined />,
    });
  }

  const closeNote = () => {
    dispatch(setNotesViewVisible(false));
    dispatch(setNotesDetails(null));
    dispatch(setCustomiseSectionState(false));
    dispatch(resetNotesDetails());
  };

  const fetchRecordingDetails =
    async (recordingId: string | number) => {
      try {
        const response = await getNotesDetails(recordingId);
        const data = response.data;
        dispatch(setNotesDetails(data));
        dispatch(setLoadingNotesState(false));
      } catch (error) {
        dispatch(setLoadingNotesState(false));
        console.error("Error fetching recording details:", error);
      }
    };

  // biome-ignore lint: no need to add fetchRecordingDetails to the dependency array
  React.useEffect(() => {
    if (notesDetails?.id && !isDemoPath) {
      fetchRecordingDetails(notesDetails.id);
    } else if (notesDetails && isDemoPath) {
      dispatch(setNotesDetails(notesDetails));
      dispatch(setLoadingNotesState(false));
    }
  }, [notesDetails?.id, dispatch, isDemoPath]);

  return (
    <Row className={`${styles[`${componentName}__main`]}`}>
      {loadingNotes && (
        <>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </>
      )}
      {!loadingNotes && notesDetails && (
        <>
          <Button
            className={`${styles[`${componentName}-back`]} secondary-button`}
            onClick={closeNote}
            icon={<LeftOutlined />}
            style={isMobilePath ? { marginTop: 10 } : {}}
          >
            Back
          </Button>
          <Flex
            vertical
            gap={10}
            style={{
              width: "100%",
            }}
          >
            <Card>
              <NotesHeader
                notesDetails={notesDetails}
              />
            </Card>
            <Card>
              <Tabs
                onChange={(activeKey) => {
                  dispatch(setActiveKey(activeKey));
                }}
                items={items}
              />
            </Card>
          </Flex>
        </>
      )}
    </Row>
  );
}
