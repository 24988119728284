import {
  CalendarOutlined,
  ClockCircleOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  Flex,
  Popconfirm,
  Tooltip,
  Typography,
  message,
} from "antd";
import { AxiosError } from "axios";
import dayjs from "dayjs";
import React from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { useAppSelector } from "../../hooks/redux-hooks";
import { useIsMobileView } from "../../hooks/ui-hook";
import {
  handleDelete,
  handleSubmitNoteForProcessing,
  handleWriteNoteSubmission,
} from "../../service/notes.service";
import {
  setCreateModalState,
  setShowDemoSignupModal,
} from "../../slices/appStateSlice";
import { setShowPaymentModal } from "../../slices/userSlice";
import { AppDispatch } from "../../store";
import { ModalityMode, TNotes } from "../../types/index.type";
import { EventType, trackEvent } from "../../utils/analytics";
import {
  durationTimeFromType,
  showPaymentModal
} from "../../utils/datamanipulation.utils";
import { isMaxTimedOut } from "../../utils/recording.utils";
import { getRandomAvatarColor } from "../../utils/ui.utils";
import styles from "./notes.module.scss";
import NoteTypeBadge from "../Badge/NotesType.component";
import CategoryType from "../Category/Category.component";
import {
  getNotesHTMLStringFromItem,
  isLockedNoteOfANonPayingUser,
} from "../../domain/notes.domain";

type TNotesListProps = {
  noteRef?: React.RefObject<HTMLDivElement>;
  item: TNotes;
  index: number;
  fetchNotes: (pageNumber?: number) => void;
  resetRecordingState: () => void;
  navigateToNotesDetail: (item: TNotes) => void;
};

interface Window {
  ReactNativeWebView?: {
    postMessage: (message: any) => void;
  };
}

const NotesList = ({
  noteRef,
  item,
  index,
  fetchNotes,
  resetRecordingState,
  navigateToNotesDetail,
}: TNotesListProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const isMobile = useIsMobileView();
  const { isDemoPath, currentPage, isMobilePath } = useAppSelector(
    (state) => state.appState
  );
  const { userInfo } = useAppSelector((state) => state.auth);
  const { recordingDetail } = useAppSelector((state) => state.recordings);
  const [retryInProgress, setRetryInProgress] = React.useState(false);
  const componentName = "notes";
  /**
   * Handling Delete of the Recording.
   * @param recordingId Recording ID
   * @returns
   */
  const handleDeleteRecording = async (recordingId: number) => {
    if (isDemoPath) {
      dispatch(setShowDemoSignupModal(true));
      return;
    }
    const loading = message.loading("Deleting Note..", 0);
    trackEvent(EventType.DELETE_NOTE, { recordingId });
    await handleDelete(recordingId)
      .then(() => {
        loading();
        message.success("Recording deleted successfully");
        fetchNotes(currentPage);
      })
      .catch((error: AxiosError) => {
        loading();
        message.error("Error deleting recording. Please try again.");
        console.error("Error deleting recording:", error);
      });
  };

  const handleOpenPaymentModal = () => {
    dispatch(setShowPaymentModal(true));
  };

  const handleSubmitForProcessing = async (item: TNotes) => {
    try {
        //@ts-ignore
        if (isMobilePath && window && window.ReactNativeWebView && window.ReactNativeWebView.postMessage && 
          item.system_metadata && item.system_metadata.is_from_mobile
        ) {
          //@ts-ignore
          window.ReactNativeWebView.postMessage(JSON.stringify({
            type: "retrySubmission",
            recordingId: item.id,
            client_id: item.client
          }));
          return;
      }
      await handleSubmitNoteForProcessing({
        audio_id: item.id,
      });
      fetchNotes(currentPage);
    } catch (error) {
      console.error("Error submitting note for processing:", error);
      Swal.fire({
        icon: "error",
        title: "Note processing failed.",
        text: "Please try again.",
      });
    }
  };

  const handleTimeoutRetry = async (notes: TNotes) => {
    setRetryInProgress(true);
    const element = document.getElementById("notes-container");
    const elementMobile = document.getElementById("notes-container-mobile");
    if (element && !isMobile) {
      element?.scrollTo(0, 0);
    } else if (elementMobile) {
      elementMobile?.scrollTo(0, 0);
    }
    if (showPaymentModal(userInfo)) {
      trackEvent(EventType.FREE_TRIAL_LIMIT_MODAL);
      handleOpenPaymentModal();
      dispatch(setCreateModalState(false));
      setRetryInProgress(false);
      return;
    }
    try {
      await handleWriteNoteSubmission({
        audio_id: notes?.id,
        title: notes.title,
        note_type: notes.note_type,
        category_type: notes.category_type,
        modality_mode: ModalityMode[notes.modality_type],
        gender_type: notes.gender_type,
        client_id: notes.client,
        duration_type: notes.duration_type,
        duration_addon_type: notes.duration_addon_type,
        location_type: notes.location_type,
      });
      fetchNotes(currentPage);
      resetRecordingState();
    } catch (error) {
      console.error("Error updating note:", error);
      Swal.fire({
        icon: "error",
        title: "Note submission failed.",
        text: "Please try again.",
      });
    } finally {
      setRetryInProgress(false);
    }
  };

  const htmlString = React.useMemo(() => {
    return getNotesHTMLStringFromItem(item);
  }, [item]);

  const isCurrentSession = (item: TNotes) => {
    return (
      item.status === 2 &&
      item.id === recordingDetail.current_recording_id &&
      (item.modality_type === 0 || item.modality_type === 1)
    );
  };

  const canSubmitDraftNoteForProcessing =
    item.status === 2 &&
    !isCurrentSession(item) &&
    !isLockedNoteOfANonPayingUser(item, userInfo);

  return (
    <Flex
      key={item.id}
      ref={index === 0 ? noteRef : null}
      vertical
      className={`${styles[`${componentName}__container-left-row`]} ${
        item.error_status ||
        (item.status !== 1 &&
          isMaxTimedOut(item) &&
          styles[`${componentName}__container-left-row-error`])
      } ${
        isLockedNoteOfANonPayingUser(item, userInfo) && !item.is_incapture
          ? styles[`${componentName}__container-left-row-draft`]
          : ""
      } ${item.id}`}
      onClick={() =>
        isLockedNoteOfANonPayingUser(item, userInfo)
          ? handleOpenPaymentModal()
          : navigateToNotesDetail(item)
      }
    >
      <Flex
        style={{
          marginBottom: 10,
        }}
        justify="space-between"
      >
        {item?.client_name && (
          <Flex align="center" gap={10}>
            <Avatar
              size={"small"}
              style={{
                backgroundColor: getRandomAvatarColor(item.client_name),
              }}
            >
              {item.client_name[0].toUpperCase()}
            </Avatar>
            <Typography>{item.client_name}</Typography>
          </Flex>
        )}
        {item?.client_name && (
          <Popconfirm
            title="Delete Note"
            description="Are you sure to delete this note?"
            onConfirm={(e) => {
              e?.stopPropagation();
              handleDeleteRecording(item?.id);
            }}
            okText="Yes"
            onCancel={(e) => e?.stopPropagation()}
            cancelText="No"
          >
            <DeleteOutlined
              style={{
                color: "grey",
              }}
              className={isMobilePath || isMobile ? '' :  styles[`${componentName}__container-left-row-delete`]}
              onClick={(e) => e.stopPropagation()}
            />
          </Popconfirm>
        )}
      </Flex>
      <Flex align="center" justify="space-between">
        <Flex
          justify="space-between"
          style={{
            width: "100%",
          }}
        >
          <Typography
            className={styles[`${componentName}__container-left-row-heading`]}
          >
            {item?.title || "Untitled Session"}
            {item.status === 1 && item.error_status === 0 && !item.is_read && (
              <>
                &nbsp; <Badge count="New" color="#95de64" />
              </>
            )}
            {item.status === 2 && (
              <>
                &nbsp; <Badge count="Draft" color="#faad14" />
              </>
            )}
          </Typography>
          {item && !item.client_name && (
            <Popconfirm
              title="Delete Note"
              description="Are you sure to delete this note?"
              onConfirm={(e) => {
                e?.stopPropagation();
                handleDeleteRecording(item?.id);
              }}
              okText="Yes"
              onCancel={(e) => e?.stopPropagation()}
              cancelText="No"
              placement={isMobile ? "left" : "top"}
            >
              <DeleteOutlined
                style={{
                  color: "grey",
                }}
                className={isMobilePath || isMobile ? '' :  styles[`${componentName}__container-left-row-delete`]}
                onClick={(e) => e.stopPropagation()}
              />
            </Popconfirm>
          )}
        </Flex>
      </Flex>
      {item.status !== 1 && item.status !== 2 && item.error_status === 0 && isMaxTimedOut(item) && (
        <Typography
          className={styles[`${componentName}__container-left-error`]}
        >
          <ExclamationCircleOutlined /> &nbsp; Processing exceeded limits, our
          team is on it.&nbsp;
          {item.status === 4 && (
            <Button
              type="primary"
              disabled={retryInProgress}
              icon={<UndoOutlined />}
              size="small"
              className={styles[`${componentName}__container-left-error-retry`]}
              onClick={() => handleTimeoutRetry(item)}
            >
              Retry
            </Button>
          )}
        </Typography>
      )}
      {item.status !== 1 && item.error_status === 0 && !isMaxTimedOut(item) && (
        <>
          {item.status === 2 && isCurrentSession(item) && item.is_incapture && (
            <Typography
              className={
                styles[`${componentName}__container-left-text-processing`]
              }
            >
              <LoadingOutlined /> &nbsp; Draft - In progress
            </Typography>
          )}
          {item.status === 3 && (
            <Typography
              className={
                styles[`${componentName}__container-left-text-processing`]
              }
            >
              <LoadingOutlined /> &nbsp; Transcribing audio
            </Typography>
          )}
          {item.status === 4 && (
            <Typography
              className={
                styles[`${componentName}__container-left-text-processing`]
              }
            >
              <LoadingOutlined /> &nbsp; Generating note
            </Typography>
          )}
          {item.status !== 2 ? (
            <Typography
              className={styles[`${componentName}__container-left-text-sub`]}
            >
              Processing can take 30-90 seconds
            </Typography>
          ) : null}
        </>
      )}
      {canSubmitDraftNoteForProcessing ? (
        <Typography
          className={styles[`${componentName}__container-left-info`]}
        >
          <ExclamationCircleOutlined /> &nbsp; {item && item.system_metadata && item.system_metadata.is_from_mobile && !isMobilePath ? `This item is still in draft. Continue on mobile app to process` : 'This item is still in draft and will need to be processed. '}
          {item && item.system_metadata && item.system_metadata.is_from_mobile && !isMobilePath ? <></> : <Button
            type="primary"
            icon={<UndoOutlined />}
            size="small"
            className={styles[`${componentName}__container-left-error-retry`]}
            onClick={() => handleSubmitForProcessing(item)}
          >
            Process now 
          </Button>}
        </Typography>
      ) : isLockedNoteOfANonPayingUser(item, userInfo) && !item.is_incapture ? (
        <Typography.Paragraph
          className={styles[`${componentName}__container-left-text`]}
          style={{ filter: "blur(3px)" }}
        >
          <Tooltip title="Click here to upgrade and view the note">
            <span>
              This note is locked. Please subscribe to see its contents.
            </span>
          </Tooltip>
        </Typography.Paragraph>
      ) : null}
      {item.error_status > 0 ? (
        <>
          {item.error_status === 4 && (
            <Typography
              className={styles[`${componentName}__container-left-error`]}
            >
              <ExclamationCircleOutlined /> &nbsp; Not enough information in the
              session. Please ensure your mic and sound are working. &nbsp;{" "}
              <Button
                type="primary"
                icon={<UndoOutlined />}
                size="small"
                className={
                  styles[`${componentName}__container-left-error-retry`]
                }
                onClick={() => handleTimeoutRetry(item)}
              >
                Retry
              </Button>
            </Typography>
          )}
          {item.error_status !== 4 &&
          item.status !== 4 &&
          isMaxTimedOut(item) ? (
            <Typography
              className={styles[`${componentName}__container-left-error`]}
            >
              <ExclamationCircleOutlined /> &nbsp; Processing exceeded limits,
              our team is on it.&nbsp;{" "}
              <Button
                type="primary"
                icon={<UndoOutlined />}
                onDoubleClick={(e) => e.stopPropagation()}
                disabled={retryInProgress}
                size="small"
                className={
                  styles[`${componentName}__container-left-error-retry`]
                }
                onClick={() => handleTimeoutRetry(item)}
              >
                Retry
              </Button>
            </Typography>
          ) : item.error_status !== 4 ? (
            <Typography
              className={styles[`${componentName}__container-left-error`]}
            >
              {item.error_status === 1 ? (
                <div>
                  <ExclamationCircleOutlined /> &nbsp; There was an error processing this session
                  recording. You can try again by using Dictate to recreate the note.
                </div>
              ) : (
                <div>
                  <ExclamationCircleOutlined /> &nbsp; Error processing this session
                  recording. &nbsp;{" "}
                  <Button
                    type="primary"
                    icon={<UndoOutlined />}
                    size="small"
                    className={
                      styles[`${componentName}__container-left-error-retry`]
                    }
                    onClick={() => handleTimeoutRetry(item)}
                  >
                    Retry
                  </Button>
                </div>
              )}
            </Typography>
          ) : (
            ""
          )}
        </>
      ) : (
        item.status === 1 && (
          <Typography.Paragraph
            className={styles[`${componentName}__container-left-text`]}
          >
            <span
              // biome-ignore lint/security/noDangerouslySetInnerHtml: it's okay here
              dangerouslySetInnerHTML={{
                __html: htmlString,
              }}
            />
          </Typography.Paragraph>
        )
      )}
      <Flex
        className={styles[`${componentName}__container-left-row-footer`]}
        align="center"
        gap={10}
      >
        <span>
          <CalendarOutlined />
          &nbsp;
          {dayjs(item.uploaded_at).format("MMM D, YYYY, h:mm A")}
        </span>
        {item?.duration_type !== undefined && item?.duration_type !== null && (
          <>
            <span>&middot;</span>{" "}
            <span>
              <ClockCircleOutlined />
              &nbsp;
              {durationTimeFromType(item.duration_type)}
            </span>
          </>
        )}
        <span>&middot;</span>
        <CategoryType category_type={item?.category_type} />
        <span>&middot;</span>
        <NoteTypeBadge note_type={item?.note_type} />
      </Flex>
      <Flex
        className={styles[`${componentName}__container-left-row-footer-mobile`]}
        justify="center"
        align="space-between"
        gap={10}
        vertical
      >
        <Flex justify="space-between">
          <span>
            <CalendarOutlined />
            &nbsp;
            {dayjs(item.uploaded_at).format("MMM D, YYYY, h:mm A")}
          </span>

          {item?.duration_type !== undefined &&
            item?.duration_type !== null && (
              <>
                <span>&middot;</span>{" "}
                <span>
                  <ClockCircleOutlined />
                  &nbsp;
                  {durationTimeFromType(item?.duration_type)}
                </span>
              </>
            )}
        </Flex>
        <Flex gap={10} justify="space-between">
          <span>
            <NoteTypeBadge note_type={item?.note_type} />
          </span>
          <span>
            <CategoryType category_type={item?.category_type} />
          </span>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default NotesList;
