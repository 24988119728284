import * as Sentry from "@sentry/react";
import { TNotes } from "../types/index.type";

const serverType = process.env.REACT_APP_SERVER_TYPE;
const maxTimeout = 15 * 60 * 1000;

export const determineBestAudioFormat = () => {
  const mimeTypes = [
    "audio/webm;codecs=opus",
    "audio/ogg;codecs=opus",
    "audio/mp4;codecs=mp4a.40.2",
    "audio/ogg;codecs=vorbis",
    "audio/wav",
  ];

  for (let mimeType of mimeTypes) {
    if (MediaRecorder.isTypeSupported(mimeType)) {
      return mimeType;
    }
  }

  return "audio/mp4";
};

export const isTooShort = (recordingTime: number) => {
  const minRecordingTime = serverType === "prod" ? 15 : 1; // 15 seconds
  return recordingTime < minRecordingTime;
};

export const isMaxTimedOut = (note: TNotes) => {
  const currentDate = new Date();
  let currentStatusDate;

  // Handle the async case where the server may return immedaitely
  // but the generate note or transcribe note timestamps have not been
  // set yet in which case we continue to poll.
  //
  // This condition handles all modalities including Write as there's no
  // transcribe time associated with a Write.
  if (!note.stats_generate_note_start_at) {
    return false;
  }

  // If it was a Write modality, then ignore transcribe timestamp otherwise
  // check to see if it's null as it might have been returned before server
  // got a chance to set it.
  if (note.modality_type === 2) {
    currentStatusDate = new Date(note.stats_generate_note_start_at);
  } else {
    if (!note.stats_transcribe_start_at) {
      return false;
    }
    currentStatusDate = new Date(note.stats_transcribe_start_at);
  }
  const maxAllowedDate = new Date(currentDate.getTime() - maxTimeout);
  return note.error_status === 5 || currentStatusDate <= maxAllowedDate;
};

export const isCheckingAudioStatusForPolling =
  (note: TNotes) => {
    return (
      note.status !== 1 && !isMaxTimedOut(note) && note.error_status === 0
    );
};

export const isMediaRecorderActive = (mediaRecorder: MediaRecorder | null) => {
  if (!mediaRecorder) return false;
  if (mediaRecorder.state === "inactive") {
    Sentry.captureMessage("MediaRecorder is inactive");
  }
  return mediaRecorder?.state !== "inactive";
};

export const stopMediaRecorderAndStopTracks = (
  mediaRecorder: MediaRecorder | null | undefined,
) => {
  if (!mediaRecorder || !isMediaRecorderActive(mediaRecorder)) return;
  mediaRecorder.stop();
  // biome-ignore lint/complexity/noForEach:
  mediaRecorder?.stream.getTracks().forEach((track) => {
    track.stop();
  });
  mediaRecorder?.removeEventListener("dataavailable", () => {
    console.log("data available event listener removed");
  });
};

export const pauseMediaRecorder = (mediaRecorder: MediaRecorder | null) => {
  if (!mediaRecorder || !isMediaRecorderActive(mediaRecorder)) return;

  mediaRecorder.pause();
};

export const resumeMediaRecorder = (mediaRecorder: MediaRecorder | null) => {
  if (!mediaRecorder || mediaRecorder.state !== "paused") return;

  mediaRecorder.resume();
};
