import { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { getNotesList } from "../../service/notes.service";
import NotesSection from "./NotesSection.component";
import {
  setCurrentPage,
  setLoadingNotesState,
} from "../../slices/appStateSlice";
import { Flex, Typography } from "antd";
import ClientSelector from "./ClientSelector.component";
import styles from "./home.module.scss";
import { useAudioStatusCheck } from "../../hooks/useAudioStatusCheck";
import { eventEmitter } from "../../utils/eventEmitter";

const AllNotes = () => {
  const componentName = "home";
  const dispatch = useAppDispatch();
  const { notes, updateNotes: setNotes } = useAudioStatusCheck([]);
  const [totalNotes, setTotalNotes] = useState(0);
  const abortControllerRef = useRef<AbortController | null>(null);
  const { currentPage, selectedClient, isNotesViewVisible } = useAppSelector(
    (state) => state.appState
  );

  const fetchNotes = useCallback(
    async (pageNumber = 1) => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      abortControllerRef.current = new AbortController();
      try {
        dispatch(setLoadingNotesState(true));
        const response = await getNotesList(pageNumber);
        const data = await response.data;
        setNotes(data?.results);
        setTotalNotes(data?.count);
        if (pageNumber) {
          dispatch(setCurrentPage(pageNumber));
        }
      } catch (error) {
        console.error("Error fetching notes:", error);
      } finally {
        dispatch(setLoadingNotesState(false));
      }
    },
    [dispatch, setNotes]
  );

  useEffect(() => {
    if (!isNotesViewVisible) {
      fetchNotes(currentPage);
    }
  }, [currentPage, fetchNotes, isNotesViewVisible]);

  const refreshHandler = useCallback(() => {
    fetchNotes(currentPage);
  }, [fetchNotes, currentPage]);

  useEffect(() => {
    eventEmitter.on('refreshNotes', refreshHandler);

    return () => {
      eventEmitter.off('refreshNotes', refreshHandler);
    };
  }, [refreshHandler]);

  return (
    <>
      {!selectedClient && !isNotesViewVisible ? (
        <Flex className={styles[`${componentName}__container-left-header`]}>
          <Typography
            className={styles[`${componentName}__container-left-header-title`]}
          >
            Past Notes
          </Typography>
          <ClientSelector />
        </Flex>
      ) : null}
      <NotesSection
        notes={notes}
        setNotes={setNotes}
        totalNotes={totalNotes}
        fetchNotes={fetchNotes}
        resetRecordingState={() => {}}
      />
    </>
  );
};

export default AllNotes;